.card-border {
  border-bottom-width: 20px;
}

.nav-items {
  margin-left: 25rem;
}

@media (min-width: 768px) {
  .card-border {
    border-bottom-width: 8px;
  }

  .nav-items {
    margin-left: 15rem;
  }
}

@media (min-width: 1024px) {
  .nav-items {
    margin-left: 25rem;
  }
}
